@import '../../base.less';

.yph-login-page {
  height: 100vh;
  flex-direction: column;
  background-color: #EFFFFB;
  background-image: url("../../assets/images/bg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  width: 100%;

  .form {
    width: 565px;
    background: #FFFFFF;
    box-shadow: 0 3px 16px rgba(0, 0, 0, 0.36);
    border-radius: 27px;
    padding: 39px 40px;
    margin-top: 8%;

    .logo-box {
      display: flex;
      gap: 13px;
      align-items: center;
      margin-bottom: 36px;

      .title {
        font-size: 24px;
        font-weight: bolder;
        line-height: 33px;
        color: #333333;
      }
    }


    .item {
      margin-bottom: 48px;

    }

    .register {
      text-align: center;
      margin-top: 37px;
      margin-bottom: 34px;

      a {
        font-size: 16px;
        color: #999999;
        user-select: none;
        cursor: pointer;
      }

    }

    .options {
      display: flex;
      justify-content: space-between;


      a, span {
        color: #3D6EEF;
        font-size: 16px;
        user-select: none;
        cursor: pointer;
        line-height: 22px;
      }
    }


  }


}




@primary-color: #E97511;